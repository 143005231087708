import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { Cobrador } from './class-cobrador';

import 'rxjs/add/operator/map';

@Injectable()
export class CobradorService {
  static ENDPOINT: string = '/api/cobrador/:id';

  constructor(@Inject(HttpClient) private _http: HttpClient) {

  }
  public getAll(): Observable<any> {
    return this._http
               .get(CobradorService.ENDPOINT.replace(':id', ''));
  }

  
public getCobradoresByCompanyId(companyId:string): Observable<any> {
  return this._http
  .get(CobradorService.ENDPOINT.replace(':id', `getCobradoresByCompanyId/${companyId}`));
}
}
