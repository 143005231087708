import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';


import 'rxjs/add/operator/map';

@Injectable()
export class ProveedorService {
  static ENDPOINT: string = '/api/proveedor/:id';

  constructor(@Inject(HttpClient) private _http: HttpClient) {

  }
 public getAll():Observable<any> {
    return this._http
               .get(ProveedorService.ENDPOINT.replace(':id', ''));
  }

 public getBuscar(texto: string):Observable<any> {
    return this._http
               .get(ProveedorService.ENDPOINT.replace(':id', '') + `buscar/${texto}`);
  }

  public getBuscarByOficina(texto: string, paisId: string, oficinaId: string):Observable<any> {
    return this._http
               .get(ProveedorService.ENDPOINT.replace(':id', '') + `buscar/${paisId}/${oficinaId}/${texto}`);
  }

  public getByProveedorIdPaisIdOficinaId(Id: string,paisId: string, oficinaId: string):Observable<any> {
    return this._http
               .get(ProveedorService.ENDPOINT.replace(':id',`${Id}/${paisId}/${oficinaId}`));
  }

  public getByPaisIdOficinaId(id: string, oficinaId:string):Observable<any> {
    return this._http
               .get(ProveedorService.ENDPOINT.replace(':id',`pais/oficina/${id}/${oficinaId}`));
  }

    

public getById(id: string):Observable<any> {
    return this._http
               .get(ProveedorService.ENDPOINT.replace(':id',`${id}`));
  }

  public enviarCorreo(body: any):Observable<any> {
    // let _messageStringified = JSON.stringify(body);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this._http
              .post(ProveedorService.ENDPOINT.replace(':id', 'enviarCorreo'), body, {headers});
  }


}
