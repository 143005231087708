'use strict';
export class Cobrador {
public Id:               number;
public CompanyId:        number;
public CobradorAXId:     string;
public NombreCobrador:   string;
public Email:            string;
public CreadoPor:        string;
public FechaCreado :     Date;
public ModificadoPor :   string;
public FechaModificado : Date;    

}
