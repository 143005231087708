import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
} from '@angular/common/http';


@Injectable()
export class MonedaService {
  static ENDPOINT: string = '/api/moneda/:id';

  constructor(@Inject(HttpClient) private _http: HttpClient) {

  }
  public getAll(): Observable<any> {
    return this._http
               .get(MonedaService.ENDPOINT.replace(':id', ''));
  }

  public getById(id: string): Observable<any> {
    return this._http
               .get(MonedaService.ENDPOINT.replace(':id',`${id}`));
  }  

  
  public getByCompanyId(companyId: string): Observable<any> {
    return this._http
               .get(MonedaService.ENDPOINT.replace(':id',`getByCompanyId/${companyId}`));
  } 

  public getPrincipalByCompanyId(companyId: string): Observable<any> {
    return this._http
               .get(MonedaService.ENDPOINT.replace(':id',`getPrincipalByCompanyId/${companyId}`));
  }
}