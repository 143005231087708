import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs/Observable";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { CierreCajaDetalle } from "./class-CierreCajaDetalle";

@Injectable()
export class CierreCajaDetalleService {
  static ENDPOINT: string = "api/cierreCajaDetalle/:id";

  constructor(@Inject(HttpClient) private _http: HttpClient) {}

  public getAll(): Observable<any> {
    return this._http.get(CierreCajaDetalleService.ENDPOINT.replace(":id", ""));
  }

  public getDetalle(usuarioId: string, cierreCajaId: string): Observable<any> {
    let usuarioId2 = "sinUsuario";
    return this._http.get(
      CierreCajaDetalleService.ENDPOINT.replace(
        ":id",
        `getByCierreIdDetalle/${usuarioId2}/${cierreCajaId}`
      )
    );
  }

  public getByCompanyIdRangoFechaEstado(
    companyId: string,
    fechaInicial: string,
    fechaFinal: string,
    estado: string
  ): Observable<any> {
    return this._http.get(
      CierreCajaDetalleService.ENDPOINT.replace(
        ":id",
        `getByCompanyIdRangoFechaEstado/${companyId}/${fechaInicial}/${fechaFinal}/${estado}`
      )
    );
  }

  public getByCompanyIdClienteIdCobradorIdNoReciboNoCheque(
    companyId: string,
    clienteId: string,
    cobradorId: string,
    noRecibo: string,
    noCheque: string
  ): Observable<any> {
    return this._http.get(
      CierreCajaDetalleService.ENDPOINT.replace(
        ":id",
        `getByCompanyIdClienteIdCobradorIdNoReciboNoCheque/${companyId}/${clienteId}/${cobradorId}/${noRecibo}/${noCheque}`
      )
    );
  }

  public enviarPagosToIntiza(
    entidades: CierreCajaDetalle[],
    userId: string
  ): Observable<any> {
    let companyId = entidades[0].CompanyId.toString();

    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this._http.post(
      CierreCajaDetalleService.ENDPOINT.replace(
        ":id",
        `enviarPagosToIntiza/byCompanyId/${companyId}/${userId}`
      ),
      entidades,
      { headers }
    );
  }
}
