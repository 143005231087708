import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs/Observable";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { RecepcionFactura } from "./class-recepcionFactura";
import { Correo } from "./class-correo";

@Injectable()
export class RecepcionFacturaService {
  static ENDPOINT: string = "/api/recepcionFactura/:id";

  constructor(@Inject(HttpClient) private _http: HttpClient) {}

  public getAll(): Observable<any> {
    return this._http.get(RecepcionFacturaService.ENDPOINT.replace(":id", ""));
  }

  public getByPaisId(id: string): Observable<any> {
    return this._http.get(RecepcionFacturaService.ENDPOINT.replace(":id", id));
  }

  public getByProveedorIdPaisIdOficinaIdFactura(
    proveedorId: string,
    paisId: string,
    oficinaId: string,
    factura: string
  ): Observable<any> {
    return this._http.get(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        `${proveedorId}/${paisId}/${oficinaId}/${factura}`
      )
    );
  }

  public getByPaisIdOficinaIdToday(
    paisId: string,
    oficinaId: string,
    fechaInicial: Date,
    fechaFinal: Date
  ): Observable<any> {
    return this._http.get(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        `${paisId}/${oficinaId}/${fechaInicial}/${fechaFinal}`
      )
    );
  }

  public getByPaisIdOficinaIdProveedorIdRangoFechaFactura(
    paisId: string,
    oficinaId: string,
    proveedorId: string,
    fechaInicial: Date,
    fechaFinal: Date,
    factura: string
  ): Observable<any> {
    return this._http.get(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        `${paisId}/${oficinaId}/${proveedorId}/${fechaInicial}/${fechaFinal}/${factura}`
      )
    );
  }
  // getByPaisIdOficinaIdUsuarioIdRangoFechaFactura

  public getByPaisIdOficinaIdUsuarioIdRolUsuarioRangoFechaFactura(
    paisId: string,
    oficinaId: string,
    usuarioId: string,
    rolUsuario: string,
    fechaInicial: Date,
    fechaFinal: Date,
    factura: string
  ): Observable<any> {
    return this._http.get(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        `usuarios/${paisId}/${oficinaId}/${usuarioId}/${rolUsuario}/${fechaInicial}/${fechaFinal}/${factura}`
      )
    );
  }

  public getByProveedorIdPaisIdOficinaIdEstado(
    proveedorId: string,
    paisId: string,
    oficinaId: string,
    estado: string
  ): Observable<any> {
    return this._http.get(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        `getByProveedorIdPaisIdOficinaIdEstado/${proveedorId}/${paisId}/${oficinaId}/${estado}`
      )
    );
  }

  public add(entidad: RecepcionFactura): Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this._http.post(
      RecepcionFacturaService.ENDPOINT.replace(":id", ""),
      entidad,
      { headers }
    );
  }

  public update(entidad: RecepcionFactura): Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);
    let id = entidad.Id.toString();
    let headers = new HttpHeaders();

    headers.append("Content-Type", "application/json");

    return this._http.put(
      RecepcionFacturaService.ENDPOINT.replace(":id", id),
      entidad,
      { headers }
    );
  }

  public updateRecepcionCl(entidad: RecepcionFactura): Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);
    let headers = new HttpHeaders();
    let id = entidad.Id.toString();
    headers.append("Content-Type", "application/json");

    return this._http.put(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        `updateRecepcionCl/${id}`
      ),
      entidad,
      { headers }
    );
  }

  public enviar(entidad: Correo): Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this._http.post(
      RecepcionFacturaService.ENDPOINT.replace(
        "recepcionFactura/:id",
        "correoToProveedor"
      ),
      entidad,
      { headers }
    );
  }

  public enviarCorreotoProveedor(
    entidades: RecepcionFactura[], correo = ""
  ): Observable<any> {
    // let _messageStringified = JSON.stringify(entidades);
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this._http.post(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        "enviarCorreotoProveedor?correo=" + correo
      ),
      entidades,
      { headers }
    );
  }

  public reporteMetricas(
    paisId: string,
    oficinaId: string,
    estado: string,
    fechaInicial: string,
    fechaFinal: string
  ): Observable<any> {
    return this._http.get(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        `metricas/reporteMetricas/${paisId}/${oficinaId}/${estado}/${fechaInicial}/${fechaFinal}`
      )
    );
  }

  public obtenerCorrelativoUltimo(
    paisId: string,
    oficinaId: string,
    correlativoParcial: string
  ): Observable<any> {
    return this._http.get(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        `obtenerCorrelativo/Ultimo/${paisId}/${oficinaId}/${correlativoParcial}`
      )
    );
  }

  public obtenerRecepcionByCorrelativo(
    paisId: string,
    oficinaId: string,
    correlativo: string
  ): Observable<any> {
    return this._http.get(
      RecepcionFacturaService.ENDPOINT.replace(
        ":id",
        `obtener/recepcion/byTicket/correlativo/unico/${paisId}/${oficinaId}/${correlativo}`
      )
    );
  }
}
