import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
} from '@angular/common/http';

import { Incident } from './incident.class';
import 'rxjs/add/operator/map';

@Injectable()
export class IncidentService {
  static ENDPOINT: string = '/api/sla/incident/:id';
  constructor(@Inject(HttpClient) private _http: HttpClient) {
  }

  public getIncident(id: string): Observable<any> {

    return this._http
               .get(IncidentService.ENDPOINT.replace(':id', id));
  }
  public getByFechaInicioFechaFin(fechaInicio: string, fechaFin: string ): Observable<any> {
    return this._http
               .get(IncidentService.ENDPOINT.replace(':id', `getByFecha/${fechaInicio}/${fechaFin}`));
  }
  
  public getAssignedIdByFechaInicioFechaFin(fechaInicio: string, fechaFin: string ): Observable<any> {
    return this._http
               .get(IncidentService.ENDPOINT.replace(':id', `assignedId/getByFecha/${fechaInicio}/${fechaFin}`));
  }
}
